<template>
  <b-row>
    <b-col xl="4" lg="12" md="12" sm="12" cols="12">
      <b-card-actions
          ref="first-card"
          class="list-card"
          @refresh="refreshStop('first-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row" :style="{backgroundColor: firstHeaderManagement.backgroundColor}">
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ firstHeaderManagement.infoText }}</h4>
            </b-col>
          </div>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-avatar
                size="65px"
                variant="light-primary"
                :text="avatarText"
                class="pull-up"
            />
          </b-col>
          <b-col cols="6" class="text-center">
            <h4>
              {{
                Number(cariDetay.bakiye)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <small>
              <b-badge
                  pill
                  :variant="bakiyeStatus.variant"
              >
                {{ bakiyeStatus.text }}
              </b-badge>
            </small>
            <br>
          </b-col>
          <b-col cols="6" class="text-center media-col">
            <b-badge variant="light-primary" class="pull-up">
              <h4 class="m-0">
                {{ cariDetay.islemSayisi }}
              </h4>
            </b-badge>
            <b-badge variant="light-secondary" class="mt-50">
              Kayıtlı İşlem
            </b-badge>
          </b-col>
          <b-col cols="12" class="text-right">
            <hr>
            <b-button v-b-modal.devir-girisi variant="relief-success" class="mt-1 mr-50"
                      :style="{backgroundColor: $store.state.colors.successColor}"
            >
              <font-awesome-icon icon="credit-card" class="mr-50"/>
              Devir Girişi
            </b-button>
            <b-modal
                id="devir-girisi"
                ok-only
                no-fade
                hide-footer
                centered
                title="Devir Girişi"
            >
              <b-row>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group
                      label="Tutar"
                      label-for="basicInput"
                  >
                    <b-input-group label="Fiş No:" label-for="basicInput">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="coins"/>
                      </b-input-group-prepend>
                      <b-form-input v-model="devir.tutar" v-numericOnly autocomplete="off" :step="priceStep"/>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group label="Giriş Türü" label-for="basicInput">
                    <v-select
                        v-model="devir.hareket"
                        :components="{ OpenIndicator, Deselect }"
                        label="title"
                        :options="devir.hareketOptions"
                    >
                      <template #no-options>
                        <p>Sonuç Bulunamadı</p>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" sm="12" cols="12">
                  <b-form-group
                      label="İşlem Notu"
                      label-for="basicInput"
                  >
                    <b-form-textarea
                        id="textarea-default"
                        v-model="devir.islemNotu"
                        rows="2"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button variant="relief-success" class="float-right mt-1"
                            :style="{backgroundColor: $store.state.colors.successColor}"
                            :disabled="!devir.tutar"
                            @click="devirSave()"
                  >
                    <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                    Kaydet
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>
            <b-button v-b-modal.tahsilat-yap variant="relief-success" class="mt-1"
                      :style="{backgroundColor: $store.state.colors.successColor}"
            >
              <font-awesome-icon icon="credit-card" class="mr-50"/>
              Tahsilat Yap
            </b-button>
            <b-modal
                id="tahsilat-yap"
                ok-only
                no-fade
                hide-footer
                centered
                title="Tahsilat Yap"
            >
              <b-row>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group label="Tahsilat Yöntemi" label-for="basicInput">
                    <v-select
                        v-model="tahsilat.girisTuru"
                        :components="{ OpenIndicator, Deselect }"
                        label="title"
                        :options="tahsilat.girisTuruOptions"
                    >
                      <template #no-options>
                        <p>Sonuç Bulunamadı</p>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group
                      label="Fiş No"
                      label-for="basicInput"
                  >
                    <b-input-group label="Fiş No:" label-for="basicInput">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="hashtag"/>
                      </b-input-group-prepend>
                      <b-form-input v-model="tahsilat.fisNo" autocomplete="off"/>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group
                      label="Tutar"
                      label-for="basicInput"
                  >
                    <b-input-group label="Fiş No:" label-for="basicInput">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="coins"/>
                      </b-input-group-prepend>
                      <b-form-input v-model="tahsilat.tutar" v-numericOnly autocomplete="off" :step="priceStep"/>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="12" sm="12" cols="12">
                  <b-form-group
                      label="Açıklama"
                      label-for="basicInput"
                  >
                    <b-form-textarea
                        id="textarea-default"
                        v-model="tahsilat.aciklama"
                        rows="2"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button variant="relief-success" class="float-right mt-1"
                            :style="{backgroundColor: $store.state.colors.successColor}"
                            @click="tahsilatSave()"
                            :disabled="!tahsilat.tutar"
                  >
                    <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                    Kaydet
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
          <b-col cols="12" class="mt-2">
            <hr>
            <b-list-group flush>
              <b-list-group-item>
                <p class="font-weight-bolder">Firma Unvan : </p>
                <p class="ml-1"> {{ cariBilgileri.faturaUnvan }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">Yetkili : </p>
                <p class="ml-1"> {{ `${cariBilgileri.adi} ${cariBilgileri.soyAdi}` }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">Telefon : </p>
                <p class="ml-1"> {{ cariBilgileri.telefon }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">E-Posta : </p>
                <p class="ml-1"> {{ cariBilgileri.ePosta }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">GSM : </p>
                <p class="ml-1"> {{ cariBilgileri.gsm }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">Vergi Dairesi : </p>
                <p class="ml-1"> {{ cariBilgileri.vergiDairesi }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">Vergi No / Tc No : </p>
                <p class="ml-1"> {{ cariBilgileri.vergiNumarasi }}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p class="font-weight-bolder">Adres : </p>
                <p class="ml-1"> {{ cariBilgileri.adres }}</p>
              </b-list-group-item>
            </b-list-group>
            <hr>
            <b-button variant="relief-warning" class="float-right" :disabled="updateButtonManagement.passive"
                      @click="updateButtonManagement.action()"
            >
              <font-awesome-icon icon="pen" class="mr-50"/>
              {{ updateButtonManagement.text }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-actions>
    </b-col>
    <b-col xl="8" lg="12" md="12" sm="12" cols="12">
      <b-card-actions
          ref="first-card"
          class="list-card"
          @refresh="refreshStop('first-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row" :style="{backgroundColor: secondHeaderManagement.backgroundColor}">
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ secondHeaderManagement.infoText }}</h4>
              <b-button
                  v-if="!updateID"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :to="`/cariEkstre/${id}`"
                  class="show1-header-btn"
              >
                <font-awesome-icon icon="box-archive" class="mr-50"/>
                Detaylı Ekstre
              </b-button>
            </b-col>
          </div>
        </b-row>
        <b-button
            v-if="!updateID"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="`/cariEkstre/${id}`"
            class="mobile-row-btn mt-1"
        >
          <font-awesome-icon icon="box-archive" class="mr-50"/>
          Detaylı Ekstre
        </b-button>
        <hr>
        <div v-if="!updateID">
          <div v-if="isEkstre">
            <div
                v-for="item in ekstre"
                :key="item.kasaID"
                class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                      v-b-tooltip="item.hareket ==='1'? 'Tahsilat':'Borç Girişi'"
                      rounded
                      size="42"
                      :variant="item.hareket === '1' ? 'light-success':'light-danger'"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="turkish-lira-sign" class="fa-xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title font-weight-bolder m-0">
                    {{
                      Number(item.tutar)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </h6>
                  <b-badge
                      pill
                      variant="light-primary"
                      class="giris-turu-badge"
                  >
                    {{ $store.getters.GET_EKSTRE_GIRIS_TURU(item.girisTuru) }}
                  </b-badge>
                  <hr class="m-0 mt-50 islem-hr-color" :style="{borderColor : islemHrColor(item.hareket)}">
                  <p class="d-inline-block m-0">Açıklama : </p><strong class="d-inline-block ml-25">{{ item.aciklama }}
                </strong>
                  <br>
                </b-media-body>
                <div
                    class="font-weight-bolder text-right"
                    :class="item.hareket === 1 ? 'text-success':'text-danger'"
                >
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="pull-up"
                      size="sm"
                      @click="islemRemove(item.kasaID)"
                  >
                    <font-awesome-icon icon="trash" class="mr-50"/>
                    Kaydı Sil
                  </b-button>
                  <small class="d-block mt-50">
                    <b-badge
                        pill
                        variant="light-secondary"
                    >{{ item.kayitTarih }}
                    </b-badge>
                  </small>
                </div>
              </b-media>
              <hr>
            </div>
          </div>
          <b-alert
              v-else
              variant="primary"
              show
          >
            <div class="alert-body">
              İşlem Geçmişi Yok
            </div>
          </b-alert>
        </div>
        <cari-update v-if="updateID" :cari-data="cariDetay.cariBilgileri" @updating="updating"/>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BAvatar,
  VBModal,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BBadge,
  BInputGroupPrepend, BInputGroup, BMediaAside, BMedia, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import axios from 'axios'
import CariUpdate from '@/views/cari/cariUpdate.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BAlert,
    BFormTextarea,
    vSelect,
    BMediaBody,
    BMedia,
    BMediaAside,
    CariUpdate,
    BAvatar,
    BInputGroupPrepend,
    BInputGroup,
    BBadge,
    BFormGroup,
    BFormInput,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      devir: {
        tutar: null,
        islemNotu: null,
        hareket: {
          title: 'Cari Borcu',
          key: 2,
        },
        hareketOptions: [
          {
            title: 'Cari Alacağı',
            key: 1,
          }, {
            title: 'Cari Borcu',
            key: 2,
          },
        ],
      },
      tahsilat: {
        tutar: null,
        aciklama: null,
        fisNo: null,
        girisTuru: {
          title: 'Nakit',
          key: 1,
        },
        girisTuruOptions: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Havale',
            key: 2,
          },
          {
            title: 'Kredi Kartı',
            key: 3,
          },
        ],
      },
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      id: this.$route.params.id,
      updateID: null,
    }
  },
  computed: {
    ekstre: () => store.getters.GET_CARI_DETAY.hesapOzeti,
    cariBilgileri: () => {
      const cari = store.getters.GET_CARI_DETAY.cariBilgileri
      return {
        faturaUnvan: cari?.faturaUnvan,
        adi: cari?.adi,
        soyAdi: cari?.soyAdi,
        telefon: cari?.telefon,
        ePosta: cari?.ePosta,
        gsm: cari?.gsm,
        vergiDairesi: cari?.vergiDairesi,
        vergiNumarasi: cari?.vergiNumarasi,
        adres: cari?.adres,
      }
    },
    bakiyeStatus: () => {
      const bakiye = store.getters.GET_CARI_DETAY.bakiye
      let text,
          variant
      switch (true) {
        case bakiye > 0:
          text = 'Tahsil Edilecek'
          variant = 'light-success'
          break
        case bakiye < 0:
          text = 'Ödenecek'
          variant = 'light-danger'
          break
        default:
          text = 'Bakiye Yok'
          variant = 'light-secondary'
      }
      return {
        text,
        variant,
      }
    },
    updateButtonManagement() {
      return {
        text: this.updateID ? 'Düzenleniyor...' : 'Düzenle',
        passive: !!this.updateID,
        action: () => {
          if (!this.updateID) {
            this.updateID = this.id
          }
        },
      }
    },
    cariDetay: () => store.getters.GET_CARI_DETAY,
    avatarText: () => {
      let cari = store.getters.GET_CARI_DETAY
      return cari.adi?.slice(0, 1) + cari.soyAdi?.slice(0, 1) || ''
    },
    firstHeaderManagement() {
      return {
        backgroundColor: store.state.colors.headerRowColor,
        infoText: 'Cari Detay',
      }
    },
    secondHeaderManagement() {
      let cari = this.cariDetay
      return {
        backgroundColor: this.updateID ? '#E06469' : store.state.colors.headerRowColor,
        infoText: this.updateID ? `${`${cari.cariBilgileri.adi} ${cari.cariBilgileri.soyAdi}` || cari.cariBilgileri.faturaUnvan}  Adlı Cari Kart Düzenleniyor` : 'Tahsilat ve Borç Kayıtları (Son 10 Kayıt)',
      }
    },
  },
  watch: {},
  created() {
    this.getCariDetay()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_CARI_DETAY',
      action: 0,
    })
  },
  methods: {
    isEkstre() {
      return this.ekstre?.length > 0
    },
    islemHrColor: hareket => {
      switch (hareket) {
        case '1':
          return '#64CCC5'
        case '2':
          return '#E25E3E'
        default:
          return '#793FDF'
      }
    },
    islemRemove(kasaID) {
      const fd = this.postSchema()
      fd.append('methodName', 'kasaSil')
      fd.append('cariKartID', this.id)
      fd.append('kasaID', kasaID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.getCariDetay()
            }
          })
    },
    devirSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'devirKaydi')
      fd.append('saveData[cariKartID]', this.id)
      fd.append('saveData[hareket]', this.devir.hareket.key)
      fd.append('saveData[tutar]', this.devir.tutar || 0)
      fd.append('saveData[aciklama]', this.devir.islemNotu || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('devir-girisi')
              this.devirReset()
              store.commit({
                type: 'SET_CARI_DETAY',
                action: 1,
                list: response,
              })
            }
          })
    },
    tahsilatSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'tahsilatKayit')
      fd.append('cariKartID', this.id)
      fd.append('girisTuru', this.tahsilat.girisTuru.key)
      fd.append('tutar', this.tahsilat.tutar.replace(/,/g, '.') || 0)
      fd.append('fisNo', this.tahsilat.fisNo || '')
      fd.append('aciklama', this.tahsilat.aciklama || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('tahsilat-yap')
              this.tahsilatReset()
              store.commit({
                type: 'SET_CARI_DETAY',
                action: 1,
                list: response,
              })
            }
          })
    },
    getCariDetay() {
      const fd = this.postSchema()
      fd.append('methodName', 'cariOzet')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_CARI_DETAY',
              action: 1,
              list: response,
            })
          })
    },
    updating(value) {
      if (value === 1) {
        this.getCariDetay()
        this.updateID = null
      }
    },
    devirReset() {
      const { devir } = this
      devir.tutar = null
      devir.islemNotu = null
      devir.hareket = {
        title: 'Cari Borcu',
        key: 2,
      }
    },
    tahsilatReset() {
      const { tahsilat } = this
      tahsilat.tutar = null
      tahsilat.fisNo = null
      tahsilat.aciklama = null
      tahsilat.girisTuru = {
        title: 'Nakit',
        key: 1,
      }
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'cari')
      fd.append('cariKartID', this.id)
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.list-group-item p {
  display: inline-block;
  margin: 0;
}

.media-col {
  display: grid;
  place-items: center;
}

.pull-up {
  transition: 300ms;
}

@media (max-width: 990px) {
  .show1-header-btn {
    display: none !important;
  }
}

.giris-turu-badge {
  border-radius: 3px;
}

.islem-hr-color {
  opacity: 0.6;
}
</style>
